// Auto-generated language file from /opt/wh/whtree/modules/publisher/js/captcha/__captcha.lang.json
var registerTexts = require("@mod-tollium/js/gettid").registerTexts;
registerTexts("publisher","cs",{"site":{"captcha":{"explain":"Please check the box below","title":"Verification"}}});
registerTexts("publisher","de",{"site":{"captcha":{"explain":"Bitte setze das Häkchen","title":"Kontrolle"}}});
registerTexts("publisher","en",{"site":{"captcha":{"explain":"Please check the box below","title":"Verification"}}});
registerTexts("publisher","es",{"site":{"captcha":{"explain":"Por favor, marque la casilla a continuación","title":"Casi listo"}}});
registerTexts("publisher","nl",{"site":{"captcha":{"explain":"Vink het vakje hieronder aan","title":"Verificatie"}}});
registerTexts("publisher","pl",{"site":{"captcha":{"explain":"Zaznacz pole poniżej","title":"Weryfikacja"}}});
registerTexts("publisher","sk",{"site":{"captcha":{"explain":"Please check the box below","title":"Verification"}}});
// Adding dependency: /opt/wh/whtree/modules/publisher/language/default.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/de.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/default.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/default.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/es.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/default.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/nl.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/pl.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/default.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/default.xml
