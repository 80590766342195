import "./booking.scss";

import * as dompack from "dompack";
import * as whintegration from "@mod-system/js/wh/integration";

import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";

const months = {
  en: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  es: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
  nl: [
    "Januari",
    "Februari",
    "Maart",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Augustus",
    "September",
    "Oktober",
    "November",
    "December",
  ],
  de: [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ],
  pl: [
    "styczeń",
    "luty",
    "marzec",
    "kwiecień",
    "maj",
    "czerwiec",
    "lipiec",
    "sierpień",
    "wrzesień",
    "październik",
    "listopad",
    "grudzień ",
  ],
  sk: [
    "január",
    "február",
    "marec",
    "apríl",
    "máj",
    "jún",
    "júl",
    "august",
    "september",
    "október",
    "november",
    "december",
  ],
};

dompack.onDomReady(() => {
  //The minimum amount of days to select
  const minStay = 1;

  const fpArrival = flatpickr("#booking-arrival", {
    defaultDate: new Date(),
    minDate: "today",
  });

  const fpDeparture = flatpickr("#booking-departure", {
    defaultDate: new Date(Date.now() + 86400000),
    minDate: fpArrival.formatDate(
      fpArrival.selectedDates[0].fp_incr(minStay),
      "Y-m-d"
    ),
  });

  fpArrival.config.onChange.push(onDateChange, (dates) => {
    if (
      fpArrival.formatDate(fpArrival.selectedDates[0], "Y-m-d") >
      fpDeparture.formatDate(
        fpDeparture.selectedDates[0].fp_incr(-minStay),
        "Y-m-d"
      )
    ) {
      fpDeparture.setDate(dates[0].fp_incr(minStay));
      onDateChange([dates[0].fp_incr(minStay)], null, fpDeparture);
    }
    fpDeparture.set(
      "minDate",
      fpArrival.formatDate(fpArrival.selectedDates[0].fp_incr(minStay), "Y-m-d")
    );
  });

  fpDeparture.config.onChange.push(onDateChange);

  onDateChange(fpArrival.selectedDates, null, fpArrival);
  onDateChange(fpDeparture.selectedDates, null, fpDeparture);
});

function onDateChange(dates, _, instance) {
  const valueEl = dompack.qS(`#${instance.element.id} ~ .w-booking__value`);
  const dayValueEl = valueEl.querySelector(".w-booking__day");
  const monthValueEl = valueEl.querySelector(".w-booking__month");

  const date = dates[0];
  const month = months[whintegration.config.obj.languagecode][date.getMonth()];

  dayValueEl.textContent = date.getDate();
  monthValueEl.innerHTML = `${month}<br />${date.getFullYear()}`;
}
