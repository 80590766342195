// Auto-generated language file from /opt/whdata/installedmodules/clubtorso.20241107T123705.801Z/webdesigns/clubtorso/clubtorso.lang.json
var registerTexts = require("@mod-tollium/js/gettid").registerTexts;
registerTexts("clubtorso","cs",{"js":{"arrival":"Vyberte datum příjezdu","basedon":"Ceny jsou bez příplatků a vycházejí z ceny za dvoulůžkový pokoj.","book":"Rezervujte nyní","checkin":"Příjezd","checkout":"Odjezd","clicktobook":"Klikněte na 'Rezervujte nyní' pro rezervaci","departure":"Vyberte datum odjezdu","lang":"enGB","overview":"Přehled","package":"Pokoj","price":"Cena","resetrange":"Vymazat výběr","view":"Prohlédněte si svůj pobyt v přehledu"}});
registerTexts("clubtorso","de",{"js":{"arrival":"Wählen Sie ein Ankunftsdatum aus","basedon":"Preise verstehen sich exklusive Zuschläge und basieren auf einem Doppelzimmer.","book":"Jetzt buchen","checkin":"Einchecken","checkout":"Auschecken","clicktobook":"Klicken Sie auf 'Jetzt buchen'","departure":"Wählen Sie ein Abreisedatum aus","lang":"de","overview":"Überblick","package":"Zimmer","price":"Preis","resetrange":"Klare auswahl","view":"Betrachten Sie Ihren Aufenthalt im Überblick"}});
registerTexts("clubtorso","en",{"js":{"arrival":"Select an arrival date","basedon":"Prices are exclusive of surcharges and based on a double room.","book":"Book now","checkin":"Check-in","checkout":"Check-out","clicktobook":"Click 'Book Now' to reserve","departure":"Select a departure date","lang":"enGB","overview":"Overview","package":"Room","price":"Price","resetrange":"Clear selection","view":"View your stay in the overview"}});
registerTexts("clubtorso","es",{"js":{"arrival":"Seleccione una fecha de llegada","basedon":"Los precios no incluyen cargos adicionales y se basan en una habitación doble.","book":"Reserve ahora","checkin":"Registro","checkout":"Salida","clicktobook":"Haz clic en 'Reserve ahora'","departure":"Seleccione una fecha de salida","lang":"es","overview":"Resumen","package":"Habitación","price":"Precio","resetrange":"Clara selección","view":"Vea su estancia en el resumen"}});
registerTexts("clubtorso","nl",{"js":{"arrival":"Selecteer een aankomst datum","basedon":"Prijzen zijn exclusief toeslagen en gebaseerd op een tweepersoonskamer.","book":"Boek nu","checkin":"Inchecken","checkout":"Uitchecken","clicktobook":"Klik 'Boek nu' om te reserveren","departure":"Selecteer een vertrek datum","lang":"nl","overview":"Overzicht","package":"Kamer","price":"Prijs","resetrange":"Reset selectie","view":"Bekijk uw verblijf in het overzicht"}});
registerTexts("clubtorso","pl",{"js":{"arrival":"Wybierz datę przyjazdu","basedon":"Ceny nie zawierają dopłat i dotyczą pokoju dwuosobowego.","book":"Rezerwuj teraz","checkin":"Zaewidencjonować","checkout":"Wyewidencjonować","clicktobook":"Kliknij „Zarezerwuj teraz”, aby dokonać rezerwacji","departure":"Wybierz datę wyjazdu","lang":"pl","overview":"Przegląd","package":"Pokój","price":"Cena","resetrange":"Wyczyść wybór","view":"Zobacz swój pobyt w przeglądzie"}});
registerTexts("clubtorso","sk",{"js":{"arrival":"Vyberte dátum príchodu","basedon":"Ceny sú uvedené bez príplatkov a platia pre dvojlôžkovú izbu.","book":"Rezervovať","checkin":"Registrácia","checkout":"Odhlásiť sa","clicktobook":"Pre rezerváciu kliknite na 'Rezervovať teraz'","departure":"Vyberte dátum odchodu","lang":"sk","overview":"Prehľad","package":"Izba","price":"Cena","resetrange":"Čistý výber","view":"Pozrite si svoj pobyt v prehľade"}});
// Adding dependency: /opt/whdata/installedmodules/clubtorso.20241107T123705.801Z/language/cs.xml
// Adding dependency: /opt/whdata/installedmodules/clubtorso.20241107T123705.801Z/language/de.xml
// Adding dependency: /opt/whdata/installedmodules/clubtorso.20241107T123705.801Z/language/en.xml
// Adding dependency: /opt/whdata/installedmodules/clubtorso.20241107T123705.801Z/language/es.xml
// Adding dependency: /opt/whdata/installedmodules/clubtorso.20241107T123705.801Z/language/default.xml
// Adding dependency: /opt/whdata/installedmodules/clubtorso.20241107T123705.801Z/language/pl.xml
// Adding dependency: /opt/whdata/installedmodules/clubtorso.20241107T123705.801Z/language/sk.xml
