import "./header.scss";

import * as dompack from "dompack";

dompack.onDomReady(() => {
  let maindropdowns = dompack.qSA(
    ".ww-dropdown:not(.ww-languagemenu__option > *)"
  );

  maindropdowns.forEach((element) => {
    element
      .querySelector(".ww-dropdown-toggle")
      .addEventListener("click", () => {
        //close other
        let openedMenus = document.querySelector(
          ".custommenu__dropdown__container.show"
        );
        if (openedMenus !== null) openedMenus.classList.remove("show");

        element
          .querySelector(".custommenu__dropdown__container")
          .classList.add("show");
      });

    element
      .querySelector(".custommenu__dropdown__container")
      .addEventListener("mouseleave", (event) => {
        event.target.classList.remove("show");
      });
  });
});
