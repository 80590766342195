// Auto-generated language file from /opt/whdata/installedmodules/nubiumreviews.20241107T123830.931Z/webcomponents/index.lang.json
var registerTexts = require("@mod-tollium/js/gettid").registerTexts;
registerTexts("nubiumreviews","cs",{"js":{"showless":"Toon minder","showmore":"Toon meer =>"}});
registerTexts("nubiumreviews","de",{"js":{"showless":"Zeige weniger","showmore":"Zeig mehr =>"}});
registerTexts("nubiumreviews","en",{"js":{"showless":"Show less","showmore":"Show more =>"}});
registerTexts("nubiumreviews","es",{"js":{"showless":"Muestra menos","showmore":"Ver el complejo =>"}});
registerTexts("nubiumreviews","nl",{"js":{"showless":"Toon minder","showmore":"Toon meer =>"}});
registerTexts("nubiumreviews","pl",{"js":{"showless":"Pokaż mniej","showmore":"Pokaż więcej =>"}});
registerTexts("nubiumreviews","sk",{"js":{"showless":"Ukážte menej","showmore":"Zobraziť viac =>"}});
// Adding dependency: /opt/whdata/installedmodules/nubiumreviews.20241107T123830.931Z/language/default.xml
// Adding dependency: /opt/whdata/installedmodules/nubiumreviews.20241107T123830.931Z/language/de.xml
// Adding dependency: /opt/whdata/installedmodules/nubiumreviews.20241107T123830.931Z/language/en.xml
// Adding dependency: /opt/whdata/installedmodules/nubiumreviews.20241107T123830.931Z/language/es.xml
// Adding dependency: /opt/whdata/installedmodules/nubiumreviews.20241107T123830.931Z/language/default.xml
// Adding dependency: /opt/whdata/installedmodules/nubiumreviews.20241107T123830.931Z/language/pl.xml
// Adding dependency: /opt/whdata/installedmodules/nubiumreviews.20241107T123830.931Z/language/sk.xml
