import * as dompack from "dompack";
import * as mso from "@mod-mso/webdesigns/mso/mso";

import "@mod-clubtorso/web/components/header/header";
import "@mod-mso_templates/webdesigns/installa/installa";
import "./widgets/booking/booking";
import "./widgets/twocols/twocols";

import "./clubtorso.scss";
import "./clubtorso.lang.json";

dompack.onDomReady(() => {
  new mso.MSOSite();
});
